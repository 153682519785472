<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>GEOCAPAS</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>GEOCAPAS  ÁREAS</v-breadcrumbs-item>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            rounded
            class="mb-2"
            :to="{
              name: 'planning.createGeocapa'
            }"
          >
            Nueva Geocapa
          </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]

          }"
          :headers="headers"
          :items="events"
          :items-per-page="15"
          :search="search"
          item-key="id"
        >
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editCase(item.id,item.type_area)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Editar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadGeoJson(item.geojson,item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-cloud-download</v-icon>
                    Descargar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { listGeoCapas ,listGeoCapasJson} from "@/services/Planning";
import Swal from "sweetalert2";

export default {
  name: "ListGeoCapas",
  props : {
  },
  data() {
    return {

      sidewalks: [],
      events: [],
      fileJson: [],
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "FECHA", value: "created_at" },
        { text: "TIPO DE ÁREA", value: "type_area_name" },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    editCase(id, type_area) {
      this.$router.push({
        name: "planning.editGeocapa",
        params: {
          id: id,
          type_area: type_area
        },
      });
    },
    async downloadGeoJson(geojson,id) {
      /**
       * [ obtiene la data del GeoJson y la convierte en archivo de texto para descargar]
       * @param {[this.fileJson]} archivoJson [archivo  con coordenadas geojson]
       */
      let listJson = (await listGeoCapasJson(id))?.data;
      this.fileJson = listJson;
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.fileJson));
        var a = document.createElement("a");
        console.log(this.fileJson)
        a.setAttribute("download", `${geojson}.json`);
        a.setAttribute("href", dataStr);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
  },
  async created() {
    /**
     * [ trae la data de lalista de Geocapas ]
     * @param {[this.events]} GeoCapas [Geocapas]
     */
    try {
      this.showLoader();
      let listEventsResponse = (await listGeoCapas())?.data;
      this.events = listEventsResponse;
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped></style>
